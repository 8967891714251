/**
 * Script relacionado ao menu com as seguintes funções:
 * - Abrir/fechar menu
 * - Filtrar menu
 * */
(function($) {
	$(function() {	
        //Abrir/fechar menu pelo botão de menu
        $('.navbar-toggle').click(function() {
            $('#menu').toggleClass('aberto');
            $('.navbar').toggleClass('menu-aberto');
            if ( isMobile() ) return false;
            $('#menu-filtro').focus();
        });

        //focar o campo de filtro quando estiver na página inicial
        if( window.location.pathname === '/home' && !(isMobile()) )
            $('#menu-filtro').focus();
	});
})(jQuery);

var Nav 	  			= {};
//var NavCli 	  			= {};			
var isNavOpen 			= false;

var aniversariantesHTML = '';

document.addEventListener('DOMContentLoaded', function() {

	var niverTitle = `
		<div style="display: inline-block;
			margin-bottom: 0;
			font-weight: normal;
			text-align: center;
			vertical-align: middle;
			-ms-touch-action: manipulation;
			touch-action: manipulation;
			background-image: none;
			white-space: nowrap;
			/* padding: 6px 12px; */
			font-size: 14px;
			line-height: 1.428571429;
			border-radius: 4px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			width: 100%;">
				<span style="font-size: 24px; font-weight: 600;">Aniversariantes</span>
				<input
					onclick="mudarStatusAniversario(this);"
					id="alterarAbrirAniversario2"
					class="alterarAbrirAniversario2"
					type="checkbox"
					data-title="Abrir Automaticamente"
					style="
						display: inline-block; 
						float: right; 
						margin: 0; 
						cursor: pointer;"> 
		</div>
	`;

    Nav = new hcOffcanvasNav('#card-niver', {
        navTitle: $(niverTitle),
        levelTitles: true,
        levelTitleAsBack: true,
        position: 'right',
        disableBody: false,
		navClass: 'offcanvas-nav',
		pushContent: aniversariantesHTML,
		swipeGestures: false
    });

    /*NavCli = new hcOffcanvasNav('#card-niver-cliente', {
        navTitle: $(niverTitle),
        levelTitles: true,
        levelTitleAsBack: true,
        position: 'right',
        disableBody: false,
		navClass: 'offcanvas-nav',
		pushContent: aniversariantesHTML,
		swipeGestures: false
    });*/

});

;(function(angular) {
	var MenuCtrl = function($scope,$ajax,$q,$rootScope,$timeout,$localStorage,$window, Upload, SpeechRecognition) {     
        var vm = this;
        
		vm.SpeechRecognition = new SpeechRecognition();

		vm.iniciarReconhecimentoDeVoz = function(){
			vm.SpeechRecognition.iniciar();
		};

        vm.itens               = [];
		vm.$storage            = $localStorage;

		/*
		vm.loadNotifications = function(){

			var self = this;

			var notifStorage = localStorage.getItem('ngStorage-notificacoes');

			if(trim_null(notifStorage) != ''){

				var dados = JSON.parse(notifStorage);

				self.assembleNotifications(dados);

			} else {

				$ajax.post('/listarNotificacoes').then(function(response){

					var dados = JSON.stringify(response);

					localStorage.setItem('ngStorage-notificacoes', dados);

					self.assembleNotifications(response);

				});

			}

		}

		vm.assembleNotifications = function(dados){

			var html = '';
			var notificationsHTML = $('#notifications-list');

			dados.forEach(function(item, index){
					
				if(item.TIPO == 6){
					
					html += `<li class="li-${index} TIPO-${item.TIPO}"><div class="alert alert-info" role="alert">${item.MENSAGEM}</div></li>`;

				} else if (item.TIPO == 5) {

					html += `<li class="li-${index} TIPO-${item.TIPO}"><div class="alert alert-warning" role="alert">${item.MENSAGEM}</div></li>`;

				} else {

					html += `<li class="li-${index} TIPO-${item.TIPO}"><div class="alert alert-secondary" role="alert">${item.MENSAGEM}</div></li>`;

				}

			});

			notificationsHTML.html(html);
			sidebar.classList.toggle('show');

		}

		vm.closeNotification = () => sidebar.classList.remove('show');

		*/

		vm.toggleOffcanvas = function(){

			if(window.location.href != `${urlhost}/home`){

				var nav_styles = {
					width: "500px",
					height: "calc(100% - 75px)",
					top: "7.25rem"
				}
		
				$('.offcanvas-nav .nav-container').css(nav_styles);
				$('.offcanvas-nav .nav-container').css(nav_styles);
		
			} else {
		
				var nav_styles = {
					width: "500px",
					height: "100%"
				}
		
				$('.offcanvas-nav .nav-container').css(nav_styles);
				$('.offcanvas-nav .nav-container').css(nav_styles);
		
			}
		
			Nav.toggle();

			var param_niver = Number(trim_null($('#_abrir_aniversario').val()));
						
			if(param_niver == 1){

				$('.alterarAbrirAniversario').attr('checked', true);

				// $('.alterarAbrirAniversario').css('display','inline-block');
				// $('.alterarAbrirAniversario2').css('display','none');

			}else{

				$('.alterarAbrirAniversario').attr('checked', false);

				// $('.alterarAbrirAniversario').css('display','none');
				// $('.alterarAbrirAniversario2').css('display','inline-block');

			}

		}

		vm.toggleBdayPanel = function(classe_niver){

			if(typeof classe_niver == 'undefined'){
				var sidebar = $('.bday');
			}else{
				var sidebar = $('.bday'+classe_niver);
			}
			

			var param_niver = Number(trim_null($('#_abrir_aniversario').val()));
						
			if(param_niver == 1){
		
				$('.alterarAbrirAniversario').attr('checked', true);
		
				// $('.alterarAbrirAniversario').css('display','inline-block');
				// $('.alterarAbrirAniversario2').css('display','none');
		
			}else{
		
				$('.alterarAbrirAniversario').attr('checked', false);
		
				// $('.alterarAbrirAniversario').css('display','none');
				// $('.alterarAbrirAniversario2').css('display','inline-block');
		
			}

			for(var index = 0; index < sidebar.length; index++) {
				var element = sidebar[index];
				var abrir   = true;
				if($(element).hasClass('clientes')){
					var lista_clientes = $(element).find('ul#obj_lista_aniversariantes_clientes').first().html();
					if(trim_null(lista_clientes)    == '' || $('#_abrir_aniversario_cliente').val() == 0){
						abrir   = false;
					}
				}
	
				if($(element).hasClass('colaboradores')){				
					var lista_colaborador = $(element).find('ul#obj_lista_aniversariantes').first().html();
					if(trim_null(lista_colaborador) == '' || $('#_abrir_aniversario').val() <= 0){
						abrir   = false;
					}
				}
	
				if(abrir){
					if($(element).hasClass('clientes')){
						if($('.bday.notifications-panel.colaboradores').hasClass('show')){
							$('.bday.notifications-panel.clientes').first().css('right','500px');
						}else{
							$('.bday.notifications-panel.clientes').first().css('right','');
						}
					}
	
					if($(element).hasClass('colaboradores')){
						if($('.bday.notifications-panel.clientes').hasClass('show')){
							$('.bday.notifications-panel.colaboradores').first().css('right','500px');
						}else{
							$('.bday.notifications-panel.colaboradores').first().css('right','');
						}
					}
					$(element).toggleClass('show');	

					if($(element).hasClass('colaboradores') && $('.bday.notifications-panel.colaboradores').first().hasClass('show') == false){
	
						if($('.bday.notifications-panel.clientes').first().hasClass('show')){
							$('.bday.notifications-panel.clientes').first().css('right','');
						}
					}
	
					if($(element).hasClass('clientes') && $('.bday.notifications-panel.clientes').first().hasClass('show') == true){
						var a = document.getElementById('dia_atual');
						$('#obj_lista_aniversariantes_clientes').parent().parent().parent().parent().parent().scrollTop(0);
						if(a !== null){
							$('#obj_lista_aniversariantes_clientes').parent().parent().parent().parent().parent().scrollTop($('#dia_atual').parent().get(0).getBoundingClientRect().top-$('#obj_lista_aniversariantes_clientes').get(0).getBoundingClientRect().top);
						}
					}
				}			
			}

			//if(sidebar.length > 1){
				// for(var index = 0; index < sidebar.length; index++) {
				// 	var element = sidebar[index];
				// 	$(element).toggleClass('show');
				// }
			/*}else{
				sidebar.toggleClass('show');
			}*/
		
			// sidebar.toggleClass('show');
		
		}

		vm.loadAniversariantes = function(){

			if(!isNavOpen){

				vm.executarAniversariantes().then(function(html){

					aniversariantesHTML = html;

					if(typeof html === 'object'){

						Nav.update({
							pushContent: $('#lista_aniversariantes').html(html.COLABORADORES)
						}, true);

						// NavCli.update({
						// 	pushContent: $('#lista_aniversariantes_cliente').html(html.CLIENTES)
						// }, true);

					}else{

						Nav.update({
							pushContent: $('#lista_aniversariantes').html(html)
						}, true);

					}

					vm.toggleOffcanvas();

					isNavOpen = true;

				});

			} else {

				vm.toggleOffcanvas();

				isNavOpen = false;

			}

		}

		vm.loadAniversariantes2 = function(){

			var nav = $('.bday');
		
			if(!nav.hasClass('show')){
		
				executarAniversariantes().then(function(html){
		
					aniversariantesHTML = html;
					if(typeof aniversariantesHTML === 'object'){
						if($('#_abrir_aniversario').val() == 1){
							$('#obj_lista_aniversariantes').html(aniversariantesHTML.COLABORADORES);	
						}
						
						if($('#_abrir_aniversario_cliente').val() == 1){
							$('#obj_lista_aniversariantes_clientes').html(aniversariantesHTML.CLIENTES);	
						}
					}else{
						if($('#_abrir_aniversario').val() == 1){
							$('#obj_lista_aniversariantes').html(aniversariantesHTML);	
						}
					}
			
					vm.toggleBdayPanel();
			
				});
		
			} else {
		
				vm.toggleBdayPanel();
		
			}

		}

		vm.defineBdayTargetURL = function(screen_origin){

			var url = trim_null(screen_origin);

			var targetUrl = '/listarAniversarios';
		
			if(url != ''){
		
				switch (url) {
		
					case `${urlhost}/_23540`:
			
						targetUrl = '/listarAniversariosDia';
						
						break;
				
					default:
						break;
			
				}
		
			}
		
			return targetUrl;

		};
		
		vm.executarAniversariantes = function(e){

			var screen_origin = window.location.href;

			var targetUrl = vm.defineBdayTargetURL(screen_origin);
            
			return new Promise((resolve, reject) => {

				var type = 'POST',
				url		= targetUrl,
				data	= {'filtro': ''},
				success	= async function(response) {
					var tipo_exibicao 			 = 1;
					var clientes_aniversariantes = [];
					if(typeof response.DADOS_ANIVERSARIANTES != 'undefined'){
						tipo_exibicao 			 = 2;
						clientes_aniversariantes = response.CLIENTES_ANIVERSARIANTES;
						response 				 = response.DADOS_ANIVERSARIANTES;
					}

					$('#periodo_clientes_aniversarios').html('Período de '+moment(moment(moment().startOf('week').toDate()).add('1','days')).format('DD/MM/YYYY')+' a '+moment(moment(moment().endOf('week').toDate()).add('1','days')).format('DD/MM/YYYY'));
					
					if(response.length > 0 || clientes_aniversariantes.length > 0){
						var dias_niver  = [];
						var dias_niver2 = [];

						for(var index = 0; index < response.length; index++) {
							var element = response[index];
							dias_niver.push({DIA:element.DIA});
						}

						for(var index = 0; index < clientes_aniversariantes.length; index++) {
							var element = clientes_aniversariantes[index];
							dias_niver2.push({DIA:element.DIA});
						}
		
						dias_niver  = removeDupesInArray(dias_niver,'DIA');		
						dias_niver2 = removeDupesInArray(dias_niver2,'DIA');
		
						for(var index = 0; index < dias_niver.length; index++){
							dias_niver[index].ANIVERSARIANTES = []
							for(var index2 = 0; index2 < response.length; index2++) {
								var element2 = response[index2];
								if(dias_niver[index].DIA == element2.DIA){
									dias_niver[index].ANIVERSARIANTES.push(element2);
								}
							}
						}

						for(var index = 0; index < dias_niver2.length; index++){
							dias_niver2[index].ANIVERSARIANTES = []
							for(var index2 = 0; index2 < clientes_aniversariantes.length; index2++) {
								var element2 = clientes_aniversariantes[index2];
	
								if(dias_niver2[index].DIA == element2.DIA){
									dias_niver2[index].ANIVERSARIANTES.push(element2);
								}
							}
						}
		
						for(var index = 0; index < dias_niver.length; index++){
							dias_niver[index].ANIVERSARIANTES = sort_by_key(dias_niver[index].ANIVERSARIANTES,'COLABORADOR');
						}
		
						for(var index = 0; index < dias_niver2.length; index++){
							dias_niver2[index].ANIVERSARIANTES = sort_by_key(dias_niver2[index].ANIVERSARIANTES,'CLIENTE');
						}
		
						var html  = '';
						var html2 = '';
		
						for(var index = 0; index < dias_niver.length; index++){
		
							html = html+'<li>';
		
							// Dia
							html += '<div style="font-family: \'Segoe UI\'; font-weight: 600; margin-bottom: 10px;"> DIA '+dias_niver[index].DIA+'</div>';
		
							// Informacoes dos aniversariantes
							for(var index2 = 0; index2 < dias_niver[index].ANIVERSARIANTES.length; index2++) {
		
								var element2 = dias_niver[index].ANIVERSARIANTES[index2];

								// var dataURI = await resizeDataURL(dias_niver[index].ANIVERSARIANTES[index2].FOTO_COLABORADOR, 500, 500);
								var dataURI = dias_niver[index].ANIVERSARIANTES[index2].FOTO_COLABORADOR;

								// var style_img = '';

								/*
								if(dataURI != urlhost + '/assets/images/perfil.png'){
		
									//style_img = 'background-image: url('+dataURI+'); background-repeat: no-repeat; background-size: 80px; height: 80px; width: 80px; border-radius: 50%; float: left; margin: 0px 10px 0px 0px; border: 1px solid;';
		
								} else {
		
									//style_img = 'background-image: url('+dataURI+'); background-repeat: no-repeat; background-size: 80px; height: 80px; width: 80px; border-radius: 50%; float: left; margin: 0px 10px 0px 0px;';
		
								}
								*/

								// Div container principal - inicio
								html += '<div style="display: flex; flex-direction: row; margin: 0px 0px 10px 0px">';

								// Foto de colaborador
								// html += '<div style="'+style_img+'"></div>';
								
								html += '<img src="'+dataURI+'" style="width: 80px; height: 80px; border-radius: 50%; margin: 0px 10px 0px 0px" alt="'+urlhost+'/assets/images/perfil.png">';
		
								// Div container info colaborador - inicio
								html += '<div>';

								// Colaborador
								html += '<div style="font-family: \'Segoe UI\';"> '+dias_niver[index].ANIVERSARIANTES[index2].COLABORADOR+'</div>';

								// Centro de Custo do colaborador
								html += '<div style="font-family: \'Segoe UI\';"> '+(dias_niver[index].ANIVERSARIANTES[index2].CCUSTO2 !== '' ? ''+dias_niver[index].ANIVERSARIANTES[index2].CCUSTO2  : '<br>')+'</div>';
								html += '<div style="font-family: \'Segoe UI\';"> '+(dias_niver[index].ANIVERSARIANTES[index2].CCUSTO3 !== '' ? ''+dias_niver[index].ANIVERSARIANTES[index2].CCUSTO3  : '<br>')+'</div><br>';
		
								// Div container info colaborador - fim
								html += '</div>'

								// Div container principal - fim
								html += '</div>';

							}
		
							html = html + '</li>';
							
						}
		
						for(var index = 0; index < dias_niver2.length; index++){
							html2 = html2+'<li>';
							var dia_colorido  = '';
							var dia_colorido2 = '';
							if(moment(dias_niver2[index].DATA,'YYYY-MM-DD').isSame(moment(moment().startOf('day')).toDate())){
								dia_colorido  = 'color: #fdff00 !important;';
								dia_colorido2 = 'id="dia_atual"';
							}
							// Dia
							html2 += '<div '+dia_colorido2+' style="font-family: \'Segoe UI\'; font-weight: 600; margin-bottom: 5px;background-color: rgba(0,0,0,0.5); font-size: 17px;'+dia_colorido+'"> DIA '+dias_niver2[index].DIA+'</div>';
		
							// Informacoes dos aniversariantes
							for(var index2 = 0; index2 < dias_niver2[index].ANIVERSARIANTES.length; index2++) {
	
								var element2 = dias_niver2[index].ANIVERSARIANTES[index2];
								// Cliente
								html2 += '<div style="font-family: \'Segoe UI\';'+dia_colorido+'"> Cliente: '+dias_niver2[index].ANIVERSARIANTES[index2].CLIENTE+'</div>';
								if(trim_null(dias_niver2[index].ANIVERSARIANTES[index2].TELEFONE) != ''){
									html2 += '<div style="font-family: \'Segoe UI\';'+dia_colorido+'"> Telefone: '+dias_niver2[index].ANIVERSARIANTES[index2].TELEFONE+'</div>';
								}
	
								if(trim_null(dias_niver2[index].ANIVERSARIANTES[index2].DATA_ULT_COMPRA) != '' && trim_null(dias_niver2[index].ANIVERSARIANTES[index2].DATA_ULT_COMPRA) != 'SEM COMPRAS'){
									html2 += '<div style="font-family: \'Segoe UI\';'+dia_colorido+'"> Dt.Últ.Compra: '+trim_null(dias_niver2[index].ANIVERSARIANTES[index2].DATA_ULT_COMPRA)+'</div>';
								}
	
								if(trim_null(dias_niver2[index].ANIVERSARIANTES[index2].ESTABELECIMENTO) != '' && trim_null(dias_niver2[index].ANIVERSARIANTES[index2].ESTABELECIMENTO) != 'SEM COMPRAS'){
									html2 += '<div style="font-family: \'Segoe UI\';'+dia_colorido+'"> Estabelecimento: '+trim_null(dias_niver2[index].ANIVERSARIANTES[index2].ESTABELECIMENTO)+'</div>';
								}
	
								if(trim_null(dias_niver2[index].ANIVERSARIANTES[index2].REPRESENTANTE) != ''){
									html2 += '<div style="font-family: \'Segoe UI\';'+dia_colorido+'"> Representante: '+trim_null(dias_niver2[index].ANIVERSARIANTES[index2].REPRESENTANTE)+'</div>';
								}
	
								if(trim_null(dias_niver2[index].ANIVERSARIANTES[index2].VENDEDOR) != ''){
									html2 += '<div style="font-family: \'Segoe UI\';'+dia_colorido+'"> Vendedor: '+trim_null(dias_niver2[index].ANIVERSARIANTES[index2].VENDEDOR)+'</div>';
								}
	
								html2 += '<br>';
		
							}
		
							html2 = html2 + '</li>';
							
							
						}
		

						var retorno = html;
						if(html2 != ''){
							retorno = {
								COLABORADORES: html,
								CLIENTES: html2
							};
						}
		
		
						resolve(retorno);

						if(html2 != ''){
							return {
								COLABORADORES: html,
								CLIENTES: html2
							};
						}else{
							return retorno;
						}
						
		
					}else{

						reject('Não há aniversariantes a serem exibidos!');
						// showErro('Não há aniversariantes a serem exibidos!');

						if(e.originalEvent !== undefined){
							showErro('Não há aniversariantes a serem exibidos!');
						}		
		
					}	
									
				};
		
				execAjax1(type,url,data,
					function(resposta) {
						success(resposta);
					},
					function(xhr){
						reject(xhr);
					}
				); 

			});
	
		}
		
		vm.loadEventMenus = function(){
			
			$(document)
			.on('click', '.go-favorito-1, .go-favorito-2', function (event) {
	
				var tipo_gravar_menu123 = 0;
				if(typeof $(this).attr('menu-id') != undefined){
					var menu_id  = $(this).attr('menu-id');
					if($(this).hasClass('go-favorito-1')){
						tipo_gravar_menu123 = 1;
					}else{
						tipo_gravar_menu123 = 2;
					}
	
					var objeto = {
						MENU: $(this).attr('menu-id'),
						TIPO: tipo_gravar_menu123
					}
	
					var tipo_menu = 1;
					if($(this).attr('menu-id') >= 28000){
						tipo_menu = 2;
					}
					
					var type	= "POST",
						url		= "/adicionarFavoritos",
						data	= {
							'MENU': "'CONTROLE'=>"+$(this).attr('menu-id')+",'TIPO'=> "+tipo_menu+"", 
							'TIPO': tipo_gravar_menu123
						},
						success	= function(data) {
							var text 	 = '';
							var favorito = '';						
							if(tipo_gravar_menu123 == 1){							
								text = 'Favorito adicionado com sucesso!<br>';
								$('.go-favorito-2').css('display','');
								$('.go-favorito-1').css('display','none');
								favorito = 1;
							}else{
								$('.go-favorito-2').css('display','none');
								$('.go-favorito-1').css('display','');							
								text = 'Favorito removido com sucesso!<br>';
								favorito = 0;
							}

							var jota_som = localStorage.getItem('ngStorage-menus');
							var achado = false;
							
							if(typeof jota_som == 'string'){
								jota_som = JSON.parse(jota_som);							
								for(var index = 0; index < jota_som.length; index++) {
									if(jota_som[index].CONTROLE == menu_id){
										jota_som[index].FAVORITO = favorito;
										achado = true;
										break;
									}								
								}
							}
	
							if(achado){
								//vm.filtrar_menu   = '';
								vm.$storage.menus = angular.copy(jota_som);
								
								jota_som = JSON.stringify(jota_som);
								localStorage.setItem('ngStorage-menus',jota_som);
								$rootScope.$apply(function(){
									for(var index = 0; index < vm.menus_filtered.length; index++) {
										if(vm.menus_filtered[index].CONTROLE == menu_id){
											vm.menus_filtered[index].FAVORITO = favorito;
											break;
										}								
									}
								});
							}
	
							vm.FiltrarMenus();
							showSuccess(text);
						}
	
					execAjax3(type, url, data, success, null, false);
	
				}
			})
		}

        vm.btnCarregarMenus = function() {

			fechaFiltroMenu();	// Solução paliativa

            $ajax.post('/listarMenu')
                .then(function(response) {
					
                    angular.forEach(response.menus, function(item, key){ 
						if(item.REL == 1){
							item.DESC = item.CONTROLE+' - '+item.DESCRICAO+' - '+item.ID;
						}else{
							item.DESC = item.CONTROLE+' - '+item.DESCRICAO; 
						}

						// if(item.CONTROLE === 11310){
						// 	console.log('modulo existente: \n' + item.CONTROLE + '-' + item.DESC + '-' + item.ID);
						// }
					});

                    vm.$storage.menus  = response.menus;
                    vm.$storage.grupos = response.grupos;

					// console.log(vm.$storage.menus);
					// console.log(vm.$storage.grupos);

					vm.FiltrarMenus();
                    showSuccess('Menus atualizados com sucesso!');
                }
            );  

		};

        vm.AdicionarFavoritos = function(dados,tipo) {

			vm.TIPO_FAVORITO = tipo;
			
			var objeto = {
				MENU: dados,
				TIPO: tipo
			}

            $ajax.post('/adicionarFavoritos',objeto)
                .then(function(response) {

                    angular.forEach(response.menus, function(item, key){ 
						if(item.REL == 1){
							item.DESC = item.CONTROLE+' - '+item.DESCRICAO+' - '+item.ID;
						}else{
							item.DESC = item.CONTROLE+' - '+item.DESCRICAO; 
						}
					});

                    vm.$storage.menus  = response.menus;
					vm.$storage.grupos = response.grupos;

					var text = '';
					if(vm.TIPO_FAVORITO == 1){
						text = 'Favorito adicionado com sucesso!<br>';
					}else{
						text = 'Favorito removido com sucesso!<br>';
					}

					vm.FiltrarMenus();
					showSuccess(text);
					//showSuccess('Menus atualizados com sucesso!');
					//showSuccess('Menus atualizados com sucesso!');
                }
            );  
		};

        vm.CarregarMenus = function() {
            $ajax.post('/listarMenu')
                .then(function(response) {

                    angular.forEach(response.menus, function(item, key){ 
						if(item.REL == 1){
							item.DESC = item.CONTROLE+' - '+item.DESCRICAO+' - '+item.ID;
						}else{
							item.DESC = item.CONTROLE+' - '+item.DESCRICAO; 
						}
					});
                    
                    vm.$storage.menus  = response.menus;
					vm.$storage.grupos = response.grupos;

					var modulos = response.modulos;
					var objModulos = [];
					var objModulosPai = {};

					for(var index = 0; index < modulos.length; index++) {
						var modulinho = modulos[index];
						if(trim_null(modulinho.TEXTO_EXPIRACAO) != '' && modulinho.MENU_LIBERADO == 1){
							var encontrado = false;

							for(var j = 0; j < objModulos.length; j++) {
								var estab = objModulos[j];

								if(estab.ESTABELECIMENTO_ID == modulinho.ESTABELECIMENTO_ID){
									encontrado = true;
									objModulosPai = estab;
								}
							}

							if(!encontrado){
								objModulosPai = {
									ESTABELECIMENTO_ID : modulinho.ESTABELECIMENTO_ID,
									ESTABELECIMENTO_DESCRICAO : modulinho.ESTABELECIMENTO_DESCRICAO,
									ITENS : []
								};

								objModulos.push(objModulosPai);
							}

							objModulosPai.ITENS.push(modulinho);
							
							//showAlert('MÓDULO: '+modulinho.DESCRICAO+'<br>ESTABELECIMENTO:'+modulinho.ESTABELECIMENTO_DESCRICAO+'.<br>PERÍODO: '+modulinho.PERIODO_EXPIRACAO+'.<br>'+modulinho.TEXTO_EXPIRACAO+'.');
						}							
					}

					$('#old_modulos').val(JSON.stringify(objModulos));
					$('#loaded_modulos').val(1);
					
					/*
					///////////////// - foi movido para app.AlertasENotificacoes.js

					if(vm.getCookie('NOTIFICAR_MODULOS') != ''){

						for(var index = 0; index < response.modulos.length; index++) {
							var modulinho = response.modulos[index];
							if(trim_null(modulinho.TEXTO_EXPIRACAO) != '' && modulinho.MENU_LIBERADO == 1){
								showAlert('MÓDULO: '+modulinho.DESCRICAO+'<br>ESTABELECIMENTO:'+modulinho.ESTABELECIMENTO_DESCRICAO+'.<br>PERÍODO: '+modulinho.PERIODO_EXPIRACAO+'.<br>'+modulinho.TEXTO_EXPIRACAO+'.');
							}							
						}	

						vm.deleteCookie('NOTIFICAR_MODULOS');
					}
					*/
                }
            );  
		};
		
		vm.getCookie = function(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for(var i = 0; i <ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		};

		vm.deleteCookie = function(cname) {
			var cookies = document.cookie.split(";");
			//cname = cname + "=";
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				var eqPos = cookie.indexOf("=");
				var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

				if( (name+'').trim() == cname){
					document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
				}
			}
		}

        vm.LoadMenus = function() {
            if ( vm.$storage.menus == undefined || vm.$storage.grupos == undefined ) {
                let isLoad = $('#_load_menus').val();
                if(isLoad == 1){
					vm.CarregarMenus();
				}
			} 

			if(vm.getCookie('ANIVERSARIO') != ''){
				var fintar  = Number($('#_abrir_aniversario').val());
				var fintar2 = Number($('#_abrir_aniversario_cliente').val());
				if(fintar == 1 || fintar2 == 1){
					$timeout(function(){
						// $('.go-niver').click();
						// vm.loadAniversariantes();
						vm.loadAniversariantes2();
					})	
				}
				vm.deleteCookie('ANIVERSARIO');
			}
			
		};
		
        vm.CarregarAniversarios = function() {
            $ajax.post('/listarAniversarios')
                .then(function(response) {
					vm.$storage.aniversarios = response;
					$('#modal_aniversario').modal('show');
					$('#modal_aniversario').css('z-index','99999');
                }
            );  
		};
		
		vm.fecharModalAniversario = function(){
			$('#modal_aniversario').modal('hide');
		}
        
        vm.IndexOfAttr = function(array,attr, value) {
            for(var i in array) {
                if(array[i][attr] === value) {
                    return i;
                }
            }
            return -1;
        };
        
        vm.menuSelecionar = function($event){
            
            if ( $event.keyCode === 13 ) {

				// console.log($event);

				/*
                if ( vm.menus_filtered.length == 1 ) {
                    var menus = $('#menu-filtro-itens');
                    var ancora = menus.find('a[href]').first();
                    var link = ancora.attr('href');
                    
                    $window.location.href = link;
                }
				*/

				if ( vm.lista_menus_tratados.length == 1 && vm.lista_menus_tratados[0].MENUS.length == 1) {
                    var menus = $('#menu-filtro-itens');
                    var ancora = menus.find('a[href]').first();
                    var link = ancora.attr('href');
                    
                    $window.location.href = link;
                }
				
            }
        };

		vm.setColoredModules = function($e, modulo){

			/*var grupo = $('#'+modulo).attr('grupo');
				
			if(trim_null(grupo) != "" && modulo === trim_null(grupo)){*/

				$('.'+modulo).css('background-image', 'url('+urlhost+'/assets/images/icones_menus_coloridos/'+ modulo +'.png)');
				
			//}

		};

		vm.unsetColoredModules = function($e, modulo){
				
			/*var grupo = $('.'+modulo).attr('grupo');
				
			if(trim_null(grupo) != "" && modulo === trim_null(grupo)){*/

				$('.'+modulo).css('background-image', 'url('+urlhost+'/assets/images/icones_menus/'+ modulo +'.png)');

			//}

		};

		vm.toggleUserArrow = function(){
			var el = $('.user-avatar-arrow');

			$(document).on( "click", "body", function(event ) {
				el.removeClass('toggled');
			});

			if(!el.hasClass('toggled')){
				el.addClass('toggled');
			} else {
				el.removeClass('toggled');
			}
		};
		
		vm.toggleUserArrowMob = function(){
			var el = $('.user-avatar-arrow-mob');

			$(document).on( "click", "body", function(event ) {
				el.removeClass('toggled');
			});

			if(!el.hasClass('toggled')){
				el.addClass('toggled');
			} else {
				el.removeClass('toggled');
			}
		};

		var prev_filtrar_menu = '';
		var prev_menu_grupo = '';
        
        //Abre resultado da filtragem
        function abreFiltroMenu() {

            $('#menu-filtro-resultado')
                .addClass('ativo');

            $('#btn-filtro-menu')
                .attr('tabindex', '-1');

        }

        //Fecha resultado da filtragem
        function fechaFiltroMenu() {

            $timeout(function () {
                $scope.$apply(function(){

					prev_filtrar_menu = vm.filtrar_menu;
					prev_menu_grupo	= vm.menu_grupo;

                    vm.filtrar_menu = '';
                    vm.menu_grupo = ''; 
					
                });
            }, 100);
            
            $('#menu-filtro-resultado')
                .removeClass('ativo');

            $('#btn-filtro-menu')
                .removeAttr('tabindex');
        
            if ( isMobile() ) return false;
            $('#menu-filtro')
                .val('')
                .focus();
        }

		// ----------------------------------------------------- Solução paliativa para os itens desatualizados do menu -----------------------------------------------------

		// ------------------------------------------------------------------------------------------------------------------------------------------------------------------
	
		vm.old_menu_filtro  = '';
		vm.old_grupo_filtro = '';
		vm.lista_menus_tratados = [];

		vm.FiltrarMenus = function(){

			if(((vm.old_menu_filtro+'').toUpperCase() != (vm.filtrar_menu+'').toUpperCase()) || ((vm.old_grupo_filtro+'').toUpperCase() != (vm.menu_grupo+'').toUpperCase()) || !(vm.lista_menus_tratados.length > 0)){

				vm.old_menu_filtro  = vm.filtrar_menu;
				vm.old_grupo_filtro = vm.menu_grupo;

				var lista_menus = vm.$storage.menus;
				var grupos = [];

				if(vm.filtrar_menu != ''){
					var dados = arrayFind(lista_menus ,{
						model : vm.filtrar_menu,
						fields : [    
							'CONTROLE',
							'DESCRICAO',
							'GRUPO'
						]
					});
				}else{
					var dados = lista_menus;
				}
				
				angular.forEach(dados, function(menu, key0){

					if(
						(vm.menu_grupo == '') || 
						(vm.menu_grupo != '' && vm.menu_grupo == menu.GRUPO)
					){

						var encontrado = false;
						var grupo_enc = {};

						angular.forEach(grupos, function(grupo, key){
							if(grupo.SUB_GRUPO_DESCRICAO == menu.SUB_GRUPO_DESCRICAO){
								encontrado = true;
								grupo_enc = grupo;
							}
						});
						
						if(!encontrado){
							var item1 = {SUB_GRUPO_DESCRICAO : menu.SUB_GRUPO_DESCRICAO, MENUS : []};
							var item2 = angular.copy(menu);
							item1.MENUS.push(item2);
							grupos.push(item1);
						}else{

							var encontrado = false;
							angular.forEach(grupo_enc.MENUS, function(grupomenu, key){
								if(grupomenu.DESC == menu.DESC){
									encontrado = true;
								}
							});

							if(!encontrado){
								var item = angular.copy(menu);
								grupo_enc.MENUS.push(item);
							}
						}
					}

				});

				vm.lista_menus_tratados = grupos;
				// console.log(vm.lista_menus_tratados);
			}
			
		}

		/*
		vm.PushListaMenus = function(){

			var lista_grupos = vm.$storage.menus;

			var lista_grupos_tratados = [];

			if(vm.filtrar_menu != ''){
				var dados = arrayFind(lista_grupos ,{
					model : vm.filtrar_menu,
					fields : [    
						'CONTROLE',
						'DESCRICAO',
						'GRUPO'
					]
				});
			}else{
				var dados = lista_grupos;
			}

			angular.forEach(dados, function(menu, key){

				lista_grupos_tratados.push(menu);

			});

			// vm.menus_grupos_tratados = lista_grupos_tratados;

		}
		*/
        
        vm.DropdownMenu = function( open ) {
            var btn_filtro_menu = $('#btn-filtro-menu');
            if ( vm.filtrar_menu != '' || open ) {
				abreFiltroMenu();
            } else {
				fechaFiltroMenu();
            }
        };

        //Fechar filtro do menu ao clicar em fechar
        $('#menu-fechar').click(function() {
            fechaFiltroMenu();
		});	
		
		vm.loadEventMenus();

		vm.getFotoUsuario = function(){

			var dados = $('#foto_usuario_gcweb').val();

			var file = {};

			if(trim_null(dados) != ''){

				var file = JSON.parse(dados);

			} else {

				return;

			}

			var usergc 			= $('#foto-usuario-gc');
			var usergc_menu 	= $('#foto-usuario-gc-menu');
			var usergc_menu2	= $('#foto-usuario-gc-menu-mob');
			
			if(file.MIME != '' && file.CONTENT != ''){

				var src = file.CONTENT;
				var random =  parseInt(Math.random() * (9999 - 1) + 1);

				var placeholder_img 	= `background-image: url('${urlhost}/assets/images/perfil.png?${random}');`;
				var background_img 		= `${src}?${random}`;

				usergc.css({
					"background-image" : `url('${background_img}')`,
					'height' : '50px',
					'border-radius' : '50px'
				})

				usergc_menu.css({
					"background-image" : `url('${background_img}')`,
					'height' : '50px',
					'border-radius' : '50px'
				})

				usergc_menu2.css({
					"background-image" : `url('${background_img}')`,
					'height' : '50px',
					'border-radius' : '50px'
				})

			} else {

				usergc.css({
					'border' : 'none',
					'height' : '50px',
					'border-radius' : '50px'
				})

				usergc_menu.css({
					'border' : 'none',
					'height' : '50px',
					'border-radius' : '50px'
				})

				usergc_menu2.css({
					'border' : 'none',
					'height' : '50px',
					'border-radius' : '50px'
				})

			}
	
		};

		vm.trapFocus = function(){

			var element = document.getElementById('list-menu-itens');

			var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
			var firstFocusableEl = focusableEls[0];  
			var lastFocusableEl = focusableEls[focusableEls.length - 1];
			var KEYCODE_TAB = 9;
		  
			element.addEventListener('keydown', function(e) {
			  var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
		  
			  if (!isTabPressed) { 
				return; 
			  }
		  
			  if ( e.shiftKey ) /* shift + tab */ {
				if (document.activeElement === firstFocusableEl) {
				  lastFocusableEl.focus();
					e.preventDefault();
				  }
				} else /* tab */ {
				if (document.activeElement === lastFocusableEl) {
				  firstFocusableEl.focus();
					e.preventDefault();
				  }
				}
			});

		};

		$(document)
            .on('keydown', 
                '#menu-filtro',
                'del',
                function() {
                    if ( $(this).prop('readonly') || $(this).prop('disabled') ) return false;
                    $(this).val('');
                    fechaFiltroMenu();
                    return false;
                }
            )
            .on('keydown', 
                function(e) {
					// console.log(e);

                    if ( $('#menu').hasClass('aberto') || pathname == '/home' ) {
                        
						if( $('#exibir-modal-alterar-senha').hasClass('modal-ativado') ){

							event.stopPropagation();

						} else {

							var letras = /[A-z]/;
							var numeros = /[0-9]/;
	
							var result_letras = letras.test(String.fromCharCode(e.keyCode));
							var result_numeros = numeros.test(String.fromCharCode(e.keyCode));
							var result = result_letras | result_numeros;
	
							if ( result || e.keyCode == 46 || e.keyCode == 8 ) {

								$('#menu-filtro').focus();
								
								if ( e.keyCode == 46 ) {
									var e = jQuery.Event("keydown");
									e.which = 46;
									$('#menu-filtro').trigger(e);
								}

							} 
							
						}
						
                    }
                }
            )
		;
		
		var counterGp = 0;
		var counterIt = 0;

		// REVISAR ISTO
		$(document)
			.on('keydown',
				'#menu-filtro',
				function(e){

					var menuFiltro = $('#menu-filtro');
					// counterGp = 0;

					if ( e.keyCode == 40 ) {

						// retira o foco do campo de pesquisar e executa um evento keydown do jQuery
						$(menuFiltro).blur();
						var e = jQuery.Event("keydown");
						e.which = 40;
						$(menuFiltro).trigger(e);

						// foca no primeiro elemento da lista
						var nextEl = $('#list_group_'+counterGp).next().attr('id');

						var focused = $('#'+nextEl+' > a').first();

						focused.focus();

						// counterGp++;

					} else if (e.keyCode == 38) {

						e.preventDefault();
						e.stopPropagation();
	
					}
				}
			)
		;

		vm.focusNextItem = function(evt) {

			$timeout(function () {

				var menuFiltro = $('#menu-filtro');
				// counterGp = 0;

				if ( evt.keyCode == 40 ) {

					// retira o foco do campo de pesquisar e executa um evento keydown do jQuery
					$(menuFiltro).blur();
					var e = jQuery.Event("keydown");
					e.which = 40;
					$(menuFiltro).trigger(e);

					// foca no primeiro elemento da lista
					var nextEl = $('#list_group_'+counterGp+1).next().attr('id');

					var focused = $('#'+nextEl+' > a').first();

					focused.focus();

					// counterGp++;

				} else if ( evt.keyCode == 38 ) {

					var activeElement = document.activeElement;
					var parentNodeId = document.getElementById(activeElement.id).parentElement.id;

					if(trim_null(parentNodeId) == ''){

						$('#menu-filtro-itens').scrollTop(0);

						var firstElement = $('#list_group_0').next().children();
						var firstElementId = firstElement.attr('id');

						$(`#${firstElementId}`).focus();

					} else {

						return;

					}

				}

			}, 100);

		}

		vm.alterProfilePic = function(render_pfp){

            var usergc 			= $('#foto-usuario-gc');
            var usergc_menu 	= $('#foto-usuario-gc-menu');
            var usergc_menu2	= $('#foto-usuario-gc-menu-mob');

            usergc.css({
                'background-image': `url('${render_pfp}')`,
                'height' : '50px',
                'border-radius' : '50px'
            })

            usergc_menu.css({
                'background-image': `url('${render_pfp}')`,
                'height' : '50px',
                'border-radius' : '50px'
            })

            usergc_menu2.css({
                'background-image': `url('${render_pfp}')`,
                'height' : '50px',
                'border-radius' : '50px'
            })

        }

		vm.alterUserPhotoGC = function(){
			console.log('alterar foto!');
			var el = $('#photo_file');
			el.click();
		}

		vm.processPhotoFile = function(event){

			var that = this;

            asyncGetFileBase64(event).then((resolve) => {

                angular.forEach(event.target.files, function(file, key) {

                    var size = (file.size / 1048576);
    
                    if(size <= 2){
                        var arq = {};
    
                        arq.ID 	 	 	= $('#usuario-id').val();
                        arq.NOME 	 	= file.name;
                        arq.TABELA 	 	= 'TBUSUARIO';
                        arq.TIPO 	 	= file.type;
                        arq.TAMANHO	 	= file.size;
                        arq.OBSERVACAO 	= 'Foto do Usuário';
                        arq.SEQUENCIA	= 1;
    
                        arq.BINARIO = file;
                        arq.CONTEUDO = resolve.substring(resolve.indexOf(',') + 1, resolve.length);
                        arq.CAMINHO = file.$ngfBlobUrl;
    
                        arq.CSS  = 'unknown';
                        arq.TYPE = '';
                        var arquivo_valido = false;
    
                        if(arq.TIPO.indexOf('png' ) >= 0 ){arq.CSS = 'png'; arq.TYPE = '.PNG'; arquivo_valido = true; }
                        if(arq.TIPO.indexOf('jpg' ) >= 0 ){arq.CSS = 'jpg'; arq.TYPE = '.JPG'; arquivo_valido = true; }
                        if(arq.TIPO.indexOf('jpeg') >= 0 ){arq.CSS = 'jpeg';arq.TYPE = '.JPEG'; arquivo_valido = true;}
                        if(arq.TIPO.indexOf('webp') >= 0 ){arq.CSS = 'webp';arq.TYPE = '.WEBP'; arquivo_valido = true;}
                        if(arq.TIPO.indexOf('PNG' ) >= 0 ){arq.CSS = 'png'; arq.TYPE = '.PNG'; arquivo_valido = true; }
                        if(arq.TIPO.indexOf('JPG' ) >= 0 ){arq.CSS = 'jpg'; arq.TYPE = '.JPG'; arquivo_valido = true; }
                        if(arq.TIPO.indexOf('JPEG') >= 0 ){arq.CSS = 'jpeg';arq.TYPE = '.JPEG'; arquivo_valido = true;}
                        if(arq.TIPO.indexOf('WEBP') >= 0 ){arq.CSS = 'webp';arq.TYPE = '.WEBP'; arquivo_valido = true;}
                        
                        if(arquivo_valido){

							var dados = arq;

							$ajax.postFile('/_11300/api/postUsuario_FOTOS_AVATAR',dados).then(function(response){

								console.log('gravou!');		
								var random =  parseInt(Math.random() * (9999 - 1) + 1);
                                var render = $('#render_file_photo');
    
								var resp = response.DATA_RETURN.IMAGE_URL+'?'+random;

                                render.attr('src', `${resp}`);

								that.alterProfilePic(resp);
	
							});

                        }else{
                            showErro('Não é possível adicionar arquivos que não sejam do tipo .PNG, .WEBP, .JPG e .JPEG.<br>Arquivo selecionado é do tipo: '+file.type+', Por favor verifique a extensão do arquivo e tente novamente.');	
                        }
    
                    }else{
                        showErro('Não é possível adicionar fotos com tamanho maior do que 2MB e "'+file.name+'" tem '+size.toLocaleString('pt-BR')+'MB, diminua a resolução ou comprima o arquivo e tente novamente.');
                    }
                });

            });
		
		}

		vm.getFileSizeFromBase64 = function(base64Content) {
			// Remove data URI prefix if present
			const base64Data = base64Content.replace(/^data:.*;base64,/, '');
		  
			// Decode Base64 to binary data
			const binaryData = atob(base64Data);
		  
			// Calculate the size in bytes
			const fileSizeInBytes = binaryData.length;
		  
			// Convert to human-readable format (e.g., KB, MB, GB) for better representation
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
			const fileSizeHumanReadable = (fileSizeInBytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
		  
			return {
			  bytes: fileSizeInBytes,
			  humanReadable: fileSizeHumanReadable,
			};
		};
    };	

	/** ///////////////////////////////////////////////////////////////////////////////////////////
	 * 							
	 * 									FUNÇÕES WEBCAM-EASY.JS		
	 * 
	*/ ////////////////////////////////////////////////////////////////////////////////////////////

	var webcan_is_set = false;
	var webcamElement = {};
	var canvasElement = {};
	var webcam = {};

	// Abre o modal da webcam e inicializa o recurso de mídia de usuário nos navegadores/ dispositivos
	$(document).on("click",'#usuario-alter-foto', function(){
		if(!webcan_is_set){
			webcamElement = document.getElementById('webcam');
			canvasElement = document.getElementById('canvas');
			webcam = new Webcam(webcamElement, 'user', canvasElement);
			webcan_is_set = true;
		}

		const applyUploadPhotoStyle = () => {
			$('#camera').css('display', 'none');

			$('#image_render').css({
				"display": "flex",
				"align-items": "center",
				"justify-content": "center",
				"flex-direction": "column"
			});

			$('#rendered-content').css('height', '100%');
		};

		const applySnapPhotoStyle = () => {
			$('#camera').css('display', 'inline-block');
			$('#image_render').hide();
			$('#rendered-content').css('height', 'auto');
		}

		if(webcan_is_set){
			addConfirme('<h4>Confirmação</h4>',
				'Escolha a forma de alteração da foto',
				[
					{desc: 'Câmera', class: 'btn-primary', ret: '1', hotkey: 'f1', glyphicon: 'glyphicon-camera'},
					{desc: 'Arquivo', class: 'btn-warning', ret: '2', hotkey: 'f2', glyphicon: 'glyphicon-folder-open'},
					{desc: 'Cancelar', class: 'btn-danger', ret: '3', hotkey: 'f3', glyphicon: 'glyphicon-ban-circle'},
				],
				[
					{
						ret:1, func:function(){
							webcam.start()
							.then(() => {
								applySnapPhotoStyle();

								$('#modal-foto-usuario').modal('show');
							})
							.catch(error => {
								if(error instanceof DOMException){
									applyUploadPhotoStyle();

									$('#modal-foto-usuario').modal('show');
								}else{
									showErro('Dispositivo de mídia não encontrado. Conecte ou habilite a permissão de câmera no seu navegador/dispositivo');
									console.log(error);
								}
							})
							
						}
					},
					{
						ret:2, func:function(){
							applyUploadPhotoStyle();

							$('#modal-foto-usuario').modal('show');
						}
					},
					{
						ret:3, func:function(){
							$('#modal-foto-usuario').modal('hide');

							if(webcan_is_set) webcam.stop();
						}
					}
				]
			);

			$('#media-output').css('display', 'none');

			$('.btn-post-photo-js').css('display', 'none');
			$('.btn-cancel-photo-js').css('display', 'none');
		}else{
			showErro('Dispositivo de mídia não encontrado. Conecte ou habilite a permissão de câmera no seu navegador/dispositivo');
		}
	});

	// Abre o modal da webcam e inicializa o recurso de mídia de usuário nos navegadores/ dispositivos
	/*
	$(document).on("click",'#usuario-alter-foto', function(){

		if(!webcan_is_set){
			webcamElement = document.getElementById('webcam');
			canvasElement = document.getElementById('canvas');
			webcam = new Webcam(webcamElement, 'user', canvasElement);
			webcan_is_set = true;
		}

		if(webcan_is_set){
			webcam.start()
				.then(result =>{
					addConfirme('<h4>Confirmação</h4>',
						'Escolha a forma de alteração da foto',
						[
							{desc: 'Câmera', class: 'btn-primary', ret: '1', hotkey: 'f1', glyphicon: 'glyphicon-camera'},
							{desc: 'Arquivo', class: 'btn-warning', ret: '2', hotkey: 'f2', glyphicon: 'glyphicon-folder-open'},
							{desc: 'Cancelar', class: 'btn-danger', ret: '3', hotkey: 'f3', glyphicon: 'glyphicon-ban-circle'},
						],
						[
							{
								ret:1, func:function(){
		
									$('#modal-foto-usuario').modal('show');

								}
							},
							{
								ret:2, func:function(){

									$('#camera').css('display', 'none');

									$('#image_render').css({
										"display": "flex",
										"align-items": "center",
										"justify-content": "center",
										"flex-direction": "column"
									});

									$('#rendered-content').css('height', '100%');
		
									$('#modal-foto-usuario').modal('show');

								}
							},
							{
								ret:3, func:function(){

									$('#modal-foto-usuario').modal('hide');

									webcam.stop();

								}
							}
						]
					);
				})
				.catch(err => {
					if(err instanceof DOMException){

						$('#camera').css('display', 'none');

						$('#image_render').css({
							"display": "flex",
							"align-items": "center",
							"justify-content": "center",
							"flex-direction": "column"
						});

						$('#rendered-content').css('height', '100%');

						$('#modal-foto-usuario').modal('show');

					} else {

						showErro('Dispositivo de mídia não encontrado. Conecte ou habilite a permissão de câmera no seu navegador/dispositivo');
						console.log(err);
						
					}
				});

			$('#media-output').css('display', 'none');

			$('.btn-post-photo-js').css('display', 'none');
			$('.btn-cancel-photo-js').css('display', 'none');

		}else{
			showErro('Dispositivo de mídia não encontrado. Conecte ou habilite a permissão de câmera no seu navegador/dispositivo');
		}

	});
	*/

	// Fecha o modal e encerra o recurso da webcam
	$(document).on("click", "#btn-fechar-modal-foto", function (){

		$('#modal-foto-usuario').modal('hide');

		if(webcan_is_set){
			webcam.stop();
		}

	});

	// Captura mídia do usuário e a exibe no modal
	$(document).on("click", "#capture-media", function(){

		let snapshot = webcam.snap();
		
		let el = $('#snapshot_usuario_b64');

		el.val(snapshot);

		$('#camera').css('display', 'none');
		$('#media-output').css('display', 'flex');

		$('.btn-post-photo-js').css('display', 'initial');
		$('.btn-cancel-photo-js').css('display', 'initial');

		$('#btn-fechar-modal-foto').css('display', 'none');

		$('#photo-box').attr('src', '');
		$('#photo-box').attr('src', snapshot);

	});

	// Reseta a mídia capturada
	$(document).on("click", ".btn-cancel-photo-js", function(){

		$('#camera').css('display', 'initial');

		$('#media-output').css('display', 'none');

		$('.btn-post-photo-js').css('display', 'none');
		$('.btn-cancel-photo-js').css('display', 'none');

		$('#btn-fechar-modal-foto').css('display', 'initial');

		$('#photo-box').attr('src', window.origin + '/assets/images/picture.png');
		
	});

	// Realiza o post da mídia capturada
	$(document).on("click", "#btn-post-photo-js", function(){

		var file 			= $('#snapshot_usuario_b64').val();
		var file_content 	= file.substring(22, file.length);
		var file_type		= file.slice(
			file.indexOf(':') + 1,
			file.lastIndexOf(';')
		)

		var id_usuario = $('#usuario-id').val();

		var size = getFileSizeFromBase64(file)
		var file_size = size.bytes / 1048576;

		var dados = {
			DADOS_ARQUIVO: {
				ID_USUARIO: 	id_usuario,
				NOME: 			'foto_usuario_' + id_usuario + '.' + file_type.substring(6, file_type.length),
				TIPO:			file_type,
				EXTENSAO: 		'.' + file_type.substring(6, file_type.length),
				BINARIO: 		file,
				CONTEUDO:		file_content,
				TAMANHO:		file_size.bytes,
				TABELA: 		"TBUSUARIO",
				OBSERVACAO:		"FOTO DO USUÁRIO NO SISTEMA",
				SEQUENCIA:		1
			}
		}

		var ajaxType 	= 'POST',
			ajaxUrl 	= '/_11300/api/postUserSnapshot',
			ajaxData 	= dados.DADOS_ARQUIVO;
			// ajaxXhr 	= $.ajaxSettings.xhr();

		function getFileSizeFromBase64(base64Content) {
			// Remove data URI prefix if present
			const base64Data = base64Content.replace(/^data:.*;base64,/, '');
			
			// Decode Base64 to binary data
			const binaryData = atob(base64Data);
			
			// Calculate the size in bytes
			const fileSizeInBytes = binaryData.length;
			
			// Convert to human-readable format (e.g., KB, MB, GB) for better representation
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			const i = Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
			const fileSizeHumanReadable = (fileSizeInBytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
			
			return {
				bytes: fileSizeInBytes,
				humanReadable: fileSizeHumanReadable,
			};
		}

		function ajaxSuccess(retorno){
			$('#modal-foto-usuario').modal('hide');
			$('.btn-cancel-photo-js').click();
			
			var usergc = $('#foto-usuario-gc');
			var usergc_menu = $('#foto-usuario-gc-menu');
			var usergc_menu2 = $('#foto-usuario-gc-menu-mob');

			var random = parseInt(Math.random() * (9999 - 1) + 1);

			var placeholder_img 	= `\nbackground-image: url('${urlhost}/assets/images/perfil.png?${random}');`;
			var background_img 		= `${retorno.DATA_RETURN}?${random}`;

			// var usergc_style 		= usergc.attr('style');
			// var usergc_menu_style 	= usergc_menu.attr('style');
			// var usergc_menu2_style 	= usergc_menu2.attr('style');

			usergc.css({
				"background-image" : `url('${background_img}')`,
			});

			usergc_menu.css({
				"background-image" : `url('${background_img}')`,
			});

			usergc_menu2.css({
				"background-image" : `url('${background_img}')`,
			});

			/*
			$('#foto-usuario-gc').removeAttr('src');
			$('#foto-usuario-gc-menu').removeAttr('src');
			$('#foto-usuario-gc-menu-mob').removeAttr('src');

			$('#foto-usuario-gc').attr({
				src: retorno.DATA_RETURN + '?' + random,
				style: 'width: 50px; height: 50px; border-radius: 50%;'
			});

			$('#foto-usuario-gc-menu').attr({
				src: retorno.DATA_RETURN + '?' + random,
				style: 'width: 50px; height: 50px; border-radius: 50%;'
			});

			$('#foto-usuario-gc-menu-mob').attr({
				src: retorno.DATA_RETURN + '?' + random,
				style: 'width: 50px; height: 50px; border-radius: 50%;'
			});
			*/

			if(window.location.href == urlhost + '/_11300'){

				var usergc_11300 = $('#render_foto');
				usergc_11300.css('background-image','url('+ retorno.DATA_RETURN +'?'+ random +')');

			}

			// console.log(ajaxData);
		}
			
		// function ajaxFail(ajaxXhr){
		// 	console.log(ajaxXhr);
		// }

		if(file_size <= 2){

			execAjax1(ajaxType, ajaxUrl, ajaxData, ajaxSuccess, false);

		} else {

			showErro('Não é possível adicionar fotos com tamanho maior do que 2MB e "'+dados.DADOS_ARQUIVO.NOME+'" tem '+file_size.toLocaleString('pt-BR')+'MB, diminua a resolução ou comprima o arquivo e tente novamente.');

		}
		

	});

	$(document).on('click','#logout', function(){
		deleteSimpleCookie('loginTime');
	});

    $(document)
        .on('click','#voltar-User',
            function() {
                
                function success(data) {
                    var user_id = $('#usuario-id').val();
                    window.localStorage.removeItem('ngStorage-menus');
                    window.location.href = urlhost + '/_11300';

                }

                execAjax1('POST','/_11010/voltarUser',[],success);
            }
        );
    
	MenuCtrl.$inject = ['$scope','$ajax','$q','$rootScope','$timeout','$localStorage','$window', 'Upload', 'SpeechRecognition'];
	//MenuCtrl.loadEventMenus();
    
    angular
        .module('appMenu', ['angular.filter','vs-repeat','ngStorage','gc-ajax','gc-find','gc-transform', 'ngFileUpload'])
        .controller('MenuCtrl', MenuCtrl)
    ;
    
    angular.bootstrap(document.getElementById('menu'), ['appMenu']);
    
    // Servirá para evitar erro de inicialização do app
    // no caso da tela não utilizar angular.
    angular.module('app', []);

})(angular);